/* eslint-disable react/destructuring-assignment */
/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 * See also: https://www.gatsbyjs.com/tutorial/seo-and-social-sharing-cards-tutorial/#implementation
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { getOgUrl } from '../utils/SEOUtils';

function SEO(props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle
            rootTitle
            description
            keywords
            author
            baseUrl
            rootSlug
            share {
              shareImage
              shareImageWidth
              shareImageHeight
              twitterSite
              ogSiteName
              fbAppId
            }
          }
        }
      }
    `
  );

  const defaults = site.siteMetadata;
  const { defaultTitle, rootTitle } = defaults;
  const description = props.description || defaults.description;
  const keywords = props.keywords || defaults.keywords;
  const canonical = props.canonicalPathname
    ? `${defaults.baseUrl}${defaults.rootSlug}${props.canonicalPathname}`
    : null;
  const shareImage = props.image || defaults.share.shareImage;
  const {
    share: {
      ogSiteName,
      shareImageWidth,
      shareImageHeight,
      twitterSite,
      fbAppId
    }
  } = defaults;

  // On homepage, show rootTitle first
  const ogTitle = props.title
    ? `${props.title} | ${rootTitle}`
    : `${rootTitle} | ${defaultTitle}`;

  const ogUrl = getOgUrl(defaults, props.slug);

  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang
      }}
      defaultTitle={`${rootTitle} | ${defaultTitle}`}
      title={props.title}
      titleTemplate={`%s | ${rootTitle}`}
      link={
        canonical
          ? [
              {
                rel: 'canonical',
                href: canonical
              }
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: description
        },
        {
          name: `keywords`,
          content: keywords
        },
        {
          property: `og:title`,
          content: ogTitle
        },
        {
          property: `og:site_name`,
          content: ogSiteName,
          class: `swiftype`,
          name: `site_name`,
          'data-type': `string`
        },
        {
          property: `og:description`,
          content: description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          property: `og:image`,
          content: shareImage
        },
        {
          property: `og:image:url`,
          content: shareImage
        },
        {
          property: `og:image:width`,
          content: shareImageWidth
        },
        {
          property: `og:image:height`,
          content: shareImageHeight
        },
        {
          property: `og:url`,
          content: ogUrl
        },
        {
          property: `url`,
          content: ogUrl
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:site`,
          content: twitterSite
        },
        {
          name: `twitter:creator`,
          content: defaults.author
        },
        {
          name: `fb:app_id`,
          content: fbAppId
        }
      ].concat(props.meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: '',
  image: '',
  keywords: '',
  title: '',
  canonicalPathname: null
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.array,
  keywords: PropTypes.string,
  slug: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.string,
  canonicalPathname: PropTypes.string
};

export default SEO;
