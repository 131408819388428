/** Modulo with a negative number returns itself, so `circModulus` enables a circular way
 * to navigate through a series of numbers */
const circModulus = (i, len) => ((i % len) + len) % len;

/** Moves the focus of an element circularly through a list with `<a />` tags.
 * Also accepts a function that's called with the next index */

const moveFocus = ({
  list,
  index: i,
  handler,
  listItemSelector = 'li'
}) => direction => {
  const listItems = list.querySelectorAll(listItemSelector);
  const listLength = listItems.length;
  const dirs = {
    up: (idx = 0) => circModulus(idx - 1, listLength),
    down: (idx = -1) => circModulus(idx + 1, listLength)
  };
  const nextIndex = dirs[direction]?.(i, listLength);
  // eslint-disable-next-line no-unused-expressions
  listItems[nextIndex]?.focus();
  handler(nextIndex);
};

export default moveFocus;
