import UrlUtils from '../UrlUtils';
import iconGoogle from '../../assets/images/icon-google.svg';
import iconApple from '../../assets/images/icon-apple.svg';
import iconOutlook from '../../assets/images/icon-outlook.svg';

const config = require('../../config');

export const getCalDropdownListItems = ({
  level,
  products,
  role,
  sessionDescription,
  sessionTitle,
  speakers,
  lengthOfSession,
  datetime,
  gatedLive,
  slug
}) => {
  const [speaker] = speakers || [];
  const videoId = slug.split('/')[0];
  const icalUrl = `${config.calendar.icalServer}${videoId}.ics`;
  return [
    {
      label: 'Google Calendar',
      icon: iconGoogle,
      href: UrlUtils.getGoogleCalendarUrl(
        sessionDescription,
        sessionTitle,
        speaker.speakerName,
        lengthOfSession,
        datetime,
        gatedLive,
        slug
      ),
      target: '_blank',
      rel: 'noopener noreferrer',
      level,
      products,
      role,
      sessionTitle,
      speakers
    },
    {
      label: 'Apple Calendar',
      icon: iconApple,
      href: icalUrl,
      level,
      products,
      role,
      sessionTitle,
      speakers
    },
    {
      label: 'Outlook Calendar',
      icon: iconOutlook,
      href: icalUrl,
      level,
      products,
      role,
      sessionTitle,
      speakers
    }
  ];
};
