import React from 'react';
import { Location } from '@reach/router';

const withLocation = Component => props => (
  <Location>
    {({ location }) => {
      return (
        <Component
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          search={location.search}
        />
      );
    }}
  </Location>
);

export default withLocation;
