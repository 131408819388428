export default class SessionUtils {
  static getLengthOfSession = mins => {
    // eslint-disable-next-line radix
    const duration = parseInt(mins);
    const h = duration > 60 ? Math.floor(duration / 60) : 0;
    const m = duration <= 60 ? duration : duration % 60;
    return `${h > 0 ? `${h} ${h === 1 ? 'hour' : 'hours'}` : ''}`.concat(
      `${h > 0 && m > 0 ? ' ' : ''}`,
      `${m > 0 ? `${m} ${m === 1 ? 'min' : 'mins'}` : ''}`
    );
  };

  static getRemainingTime = datetime => {
    const diffMs = new Date(datetime) - new Date(); // milliseconds between now & datetime
    const days = Math.floor(diffMs / 86400000); // days
    const hours = Math.floor((diffMs % 86400000) / 3600000); // hours
    const mins = Math.ceil(((diffMs % 86400000) % 3600000) / 60000); // minutes

    const increment = () => {
      let incrementHours = Math.floor((diffMs % 86400000) / 3600000); // hours
      incrementHours++;
      return incrementHours;
    };

    return `${days > 0 ? `${days} ${days === 1 ? 'day' : 'days'}` : ''}`.concat(
      `${days > 0 && (hours > 0 || (mins > 0 && mins < 60)) ? ' ' : ''}`,
      `${
        hours > 0 && mins < 60
          ? `${hours} ${hours === 1 ? 'hour' : 'hours'}`
          : ''
      }`,
      `${hours > 0 && mins > 0 && mins < 60 ? ' ' : ''}`,
      `${
        mins > 0 && mins < 60 ? `${mins} ${mins === 1 ? 'min' : 'mins'}` : ''
      }`,
      // if mins is 60, then add to hours
      `${mins === 60 ? `${increment()} hours` : ''}`
    );
  };
}
