import { useEffect } from 'react';

const useHandleKeyDown = ({ condition = true, handler, elementRef }) => {
  useEffect(() => {
    if (elementRef.current && condition) {
      document.addEventListener('keydown', handler);
    }
    return () => document.removeEventListener('keydown', handler);
  }, [condition, handler, elementRef]);
};

export default useHandleKeyDown;
