import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Skeleton from 'react-loading-skeleton';

const SpeakerPicture = props => {
  const { imageUrl, speakerTBIDProfile } = props;

  if (!speakerTBIDProfile) return <img src={imageUrl} alt="presenter avatar" />;

  return (
    <a href={speakerTBIDProfile} target="_blank" rel="noopener noreferrer">
      <img src={imageUrl} alt="presenter avatar" />
    </a>
  );
};

const SpeakerLink = props => {
  const { className = undefined, speakerName, speakerTBIDProfile } = props;

  if (!speakerTBIDProfile)
    return <span title={speakerName}>{speakerName}</span>;

  return (
    <a
      className={className}
      href={speakerTBIDProfile}
      target="_blank"
      rel="noopener noreferrer"
    >
      {speakerName}
    </a>
  );
};

const Speaker = props => {
  const {
    imageUrl,
    size,
    speakerName,
    speakerSubtext,
    speakerTBIDProfile,
    ready = true
  } = props;

  const speakerStyleResponsive =
    size === 'responsive' ? 'tlv-speaker--responsive' : '';

  if (!ready) {
    return (
      <div className={cx(`tlv-speaker`, speakerStyleResponsive)}>
        <div className="tlv-speaker__picture" data-testid="speaker-picture">
          <Skeleton
            height="100%"
            width="100%"
            style={{ display: 'block', borderRadius: 'unset' }}
          />
        </div>
        <div className="tlv-speaker__info">
          <div className="tlv-speaker__name" data-testid="speaker-name">
            <Skeleton height="0.875rem" width="9rem" />
          </div>
          <div className="tlv-speaker__subtext" data-testid="speaker-subtext">
            <Skeleton height="0.675rem" width="12rem" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cx(`tlv-speaker`, speakerStyleResponsive)}>
      <div className="tlv-speaker__picture" data-testid="speaker-picture">
        <SpeakerPicture
          imageUrl={imageUrl}
          speakerTBIDProfile={speakerTBIDProfile}
        />
      </div>
      <div className="tlv-speaker__info">
        <div className="tlv-speaker__name" data-testid="speaker-name">
          <SpeakerLink
            speakerName={speakerName}
            speakerTBIDProfile={speakerTBIDProfile}
          />
        </div>
        <div className="tlv-speaker__subtext" data-testid="speaker-subtext">
          {speakerSubtext}
        </div>
      </div>
    </div>
  );
};

SpeakerLink.propTypes = {
  className: PropTypes.string,
  speakerName: PropTypes.string,
  speakerTBIDProfile: PropTypes.string
};

SpeakerPicture.propTypes = {
  imageUrl: PropTypes.string,
  speakerTBIDProfile: PropTypes.string
};

Speaker.defaultProps = {
  size: 'small'
};

Speaker.propTypes = {
  imageUrl: PropTypes.string,
  size: PropTypes.oneOf(['responsive', 'small']),
  speakerName: PropTypes.string,
  speakerSubtext: PropTypes.string,
  speakerTBIDProfile: PropTypes.string,
  ready: PropTypes.bool
};

export default Speaker;
