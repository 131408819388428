import { VIDEO_STATUS } from '../constants/videoStatus';

export default ({ video, status }) => {
  const { CURRENT, UPCOMING, FINISHED, ON_DEMAND } = VIDEO_STATUS;
  const liveGatedStatuses = [CURRENT, UPCOMING];
  const onDemandGatedStatuses = [FINISHED, ON_DEMAND];
  const gatedWithLiveStatus =
    video.gatedLive && liveGatedStatuses.includes(status);
  const gatedWithOnDemandStatus =
    video.gatedOnDemand && onDemandGatedStatuses.includes(status);
  return gatedWithLiveStatus || gatedWithOnDemandStatus;
};
