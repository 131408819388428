import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import withLocation from './withLocation';
import UrlUtils from '../utils/UrlUtils';

const CustomLink = props => {
  // eslint-disable-next-line react/prop-types
  const { search, to, children, ...otherProps } = props;
  return (
    <GatsbyLink
      to={UrlUtils.preserveQueryString(to)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    >
      {children}
    </GatsbyLink>
  );
};

CustomLink.propTypes = {
  search: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export const Link = withLocation(CustomLink);
