import { useEffect } from 'react';

const useHandleClickOutside = ({ condition = true, handler }) => {
  useEffect(() => {
    if (condition) {
      document.addEventListener('mousedown', handler);
    } else {
      document.removeEventListener('mousedown', handler);
    }

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, [condition, handler]);
};

export default useHandleClickOutside;
