// KeyboardEvent.keyCode was deprecated. KeyboardEvent.code is the new standard:
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
const keys = {
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  Backspace: 'Backspace',
  Delete: 'Delete',
  Enter: 'Enter',
  Escape: 'Escape',
  Space: 'Space',
  Tab: 'Tab'
};

export default keys;
